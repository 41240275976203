import React from 'react';
import { IconProps } from './IconProps';

function ArrowSmDown_16px(props: IconProps) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';
  const title = props.title || 'ArrowSmDown 16px';

  return (
    <svg height={height} width={width} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <title>{title}</title>
      <g fill={fill}>
        <path
          d="M3.5 6.5L8 11l4.5-4.5"
          fill="none"
          stroke={fill}
          strokeWidth={strokewidth}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
        />
      </g>
    </svg>
  );
}

export default ArrowSmDown_16px;
