import { analytics } from "lib/segment";

export function segmentTrackBooking(
  action: string,
  data: {
    form: string;
    first_name: string;
    last_name: string;
    work_email: string;
  },
) {
  let segmentEventName;

  // Check if action is a stringified JSON object
  try {
    const parsedAction = JSON.parse(action);
    if (parsedAction) {
      action = `Segment ${parsedAction.action.type}` || "Generic Segment Event";
    }
  } catch (e) {
    // If JSON.parse fails, action remains unchanged
  }

  switch (action) {
    case 'availability-loaded':
      segmentEventName = 'Booking Availability Loaded';
      break;
    case 'timeslot-selected':
      segmentEventName = 'Booking Time Slot Selected';
      break;
    case 'booking-confirmed':
      segmentEventName = 'Booking Confirmed';
      break;
    case 'booked':
      segmentEventName = 'Booking Booked';
      break;
    default:
      segmentEventName = action;
      break;
  }
  if (segmentEventName) analytics?.track(segmentEventName, data);
}

export function addVisitedPageToLocalStorage(pathname: string) {
  // Get the existing visited pages from localStorage
  let visitedPages = JSON.parse(localStorage.getItem('visited_pages') || '[]');

  // If the pathname is not already in the array, add it
  if (!visitedPages.includes(pathname)) {
    visitedPages.push(pathname);
    localStorage.setItem('visited_pages', JSON.stringify(visitedPages));
  }

  return visitedPages;
}