'use client';

import clsx from 'clsx';
import CtaLink from 'components/CtaLink';
import richTextParser, { RichText } from "utils/richTextParser";
import { useState } from "react";
import Icon from "components/Icon";
import { ComponentBanner, ComponentBannerFragment } from "contentful/gql/graphql";
import { getFragmentData } from "contentful/gql";
import { assetFragment, ctaFragment } from "contentful/fragments";

const Banner = ({ body, iconImage, callToAction }: ComponentBannerFragment) => {
  const [isOpen, setIsOpen] = useState(true);
  const cta = getFragmentData(ctaFragment, callToAction);
  const icon = getFragmentData(assetFragment, iconImage);
  return (
    <div
      className={clsx(
        `dark relative z-40 bg-primary-dark-900 text-primary-light-50 transition-transform duration-300 ease-out banner`,
        !isOpen && "hidden",
      )}
    >
      <section
        id="banner"
        className="container mx-auto grid grid-cols-12 place-items-center gap-sm py-[7.5px] sm:py-xs"
      >
        <div className="hidden md:col-span-2 md:block"></div>
        <div
          className={clsx(
            "col-span-11 flex items-center justify-center text-left md:col-span-8 md:text-center [&_p]:m-0 [&_p]:!text-2xs [&_p]:!leading-snug [&_p]:sm:!text-xs [&_p]:xl:!text-sm",
          )}
        >
          {icon && icon.url ? (
            <span className="mr-2xs hidden h-md w-md rounded-full bg-icon-gradient p-3xs sm:flex">
              <Icon url={icon.url ?? ""} />
            </span>
          ) : null}
          {body && richTextParser(body as RichText)}
          <span className="md:ml-sm">{callToAction && <CtaLink {...cta} />}</span>
        </div>
        <div className="col-span-1 md:col-span-2">
          <button
            className="flex h-xs w-xs scale-90 items-center justify-center leading-none text-primary-dark-100"
            onClick={() => setIsOpen(false)}
          >
            <span className="sr-only">Dismiss</span>
            <Icon url="/assets/icons/e-remove-glyph-16.svg" />
          </button>
        </div>
      </section>
    </div>
  );
};

export default Banner;
