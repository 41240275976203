'use client';

// Component that watches the scroll position using @faceless-ui/scroll-info and adds a shadow to the header when the user scrolls down
import { useScrollInfo, ScrollInfoProvider } from '@faceless-ui/scroll-info';
import clsx from "clsx";

function HeaderShadowInner() {
  const scrollInfo = useScrollInfo();
  let shadow = scrollInfo.yPercentage > 0;
  return <div className={clsx('h-full w-full transition-shadow duration-300', shadow ? 'shadow-header' : '')}></div>;
}

export default function HeaderShadow() {
  return (
    <ScrollInfoProvider>
      <HeaderShadowInner />
    </ScrollInfoProvider>
  );
}
