"use client";

import { getFragmentData } from "contentful/gql";
import CtaLink from "components/CtaLink";
import { CtaFragment } from "contentful/gql/graphql";
import { ctaFragment } from "contentful/fragments";
import { useScrollInfo, ScrollInfoProvider } from "@faceless-ui/scroll-info";
import { useState, useEffect } from "react";

export default function HeaderCtas({ ctas }: { ctas: (CtaFragment | null | undefined)[] | undefined }) {
  return (
    <ScrollInfoProvider>
      <HeaderCtasInner ctas={ctas} />
    </ScrollInfoProvider>
  );
}

function HeaderCtasInner({ ctas }: { ctas: (CtaFragment | null | undefined)[] | undefined }) {
  const scrollInfo = useScrollInfo();
  const [showBookDemo, setShowBookDemo] = useState(false);
  useEffect(() => {
    if (scrollInfo.yPercentage > 2) {
      setShowBookDemo(true);
    } else {
      setShowBookDemo(false);
    }
  }, [scrollInfo.yPercentage]);

  const bookDemoCtaOptions = {
    label: "Book a demo",
    link: "/enterprise?lead_source=navbar",
  };

  const landingPageBookDemoCtaOptions = {
    label: "Request a demo",
    link: "#360-ads-registration-section",
  };

  return (
    <div className="col-span-3 hidden justify-end space-x-sm lg:flex">
      {ctas?.map((callToAction, index) => {
        return (
          callToAction && (
            <CtaLink
              key={callToAction.sys.id}
              {...callToAction}
              {...(index === 0 && showBookDemo ? bookDemoCtaOptions : [])}
              extendClassNames="2xl:text-xs font-medium default-link"
            />
          )
        );
      })}

      <CtaLink
        {...landingPageBookDemoCtaOptions}
        variant="primary"
        size="sm"
        extendClassNames="2xl:text-xs font-medium landing-page-link hidden"
      />
    </div>
  );
}
