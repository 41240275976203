"use client";

import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import clsx from "clsx";
import Hyperlink from "components/Hyperlink";
import Icon from "components/Icon";
import { assetFragment, menuItemFragment } from "contentful/fragments";
import { FragmentType, getFragmentData } from "contentful/gql";
import GithubLogo_16px from "icons/GithubLogo_16px";
import { cn } from "utils/functions";
import styles from "./Navbar.module.css";

export default function Navbar({
  githubStars,
  menuItems,
}: {
  githubStars: number;
  menuItems: (FragmentType<typeof menuItemFragment> | null)[] | undefined;
}) {
  return (
    <NavigationMenu.Root
      className={clsx("relative col-span-6 hidden font-body text-base font-medium leading-none lg:block")}
      delayDuration={50}
    >
      <NavigationMenu.List className="flex items-center justify-center space-x-sm text-sm lg:text-base xl:space-x-md">
        <NavigationMenu.Item>
          <NavigationMenu.Link asChild>
            <Hyperlink
              href="https://www.github.com/appsmithorg/appsmith"
              className={clsx(
                "flex items-center gap-4xs text-sm text-primary-light-500 transition-colors hover:text-primary-light-300",
              )}
              aria-label={"Appsmith Github Repo"}
            >
              <GithubLogo_16px height="16px" width="16px" title="Github Logo" />
              {githubStars} <span className="sr-only">Stars</span>
            </Hyperlink>
          </NavigationMenu.Link>
        </NavigationMenu.Item>
        {menuItems?.map((itemFragment) => {
          const item = getFragmentData(menuItemFragment, itemFragment);
          if (item?.menuCollection?.items && item.menuCollection.items.length > 0) {
            return (
              <NavigationMenu.Item key={item.sys.id}>
                <NavigationMenu.Trigger className="group/dropdown flex items-end text-base">
                  {item?.heading}
                  <span className="ml-4xs h-4 w-4 flex-shrink-0 transition-transform duration-300 group-data-[state='open']/dropdown:-rotate-180">
                    <Icon url="/assets/icons/arrow-sm-down-glyph-16.svg" />
                  </span>
                </NavigationMenu.Trigger>
                <NavigationMenu.Content
                  className={cn(
                    styles.NavigationMenuContent,
                    "absolute rounded border border-primary-dark-100/50 bg-primary-neutral-50 px-sm py-2xs pr-lg shadow-xl",
                  )}
                >
                  <ul className="grid">
                    {item?.menuCollection?.items?.map((item) => {
                      const icon = getFragmentData(assetFragment, item?.icon);
                      return (
                        item && (
                          <Hyperlink
                            key={item.sys.id}
                            href={item.link ?? ""}
                            className="group flex items-center py-3xs"
                          >
                            <div className={"relative mr-xs h-8 w-8 p-2"}>
                              <span className="absolute left-0 top-0 z-0 block h-full w-full rounded-full bg-gradient-to-br from-primary-light-500/10 to-primary-light-650/10 transition-opacity duration-300 group-hover:opacity-0"></span>
                              <span className="absolute left-0 top-0 z-0 block h-full w-full rounded-full bg-gradient-to-br from-primary-light-500 to-primary-light-650 opacity-0 transition-opacity duration-300 group-hover:opacity-100"></span>
                              <span className="relative z-10 block h-full w-full text-primary-light-500 transition-colors duration-300 group-hover:text-primary-neutral-50">
                                <Icon strokeWidth={1} url={icon?.url ?? ""} />
                              </span>
                            </div>
                            <span className="text-sm font-medium text-primary-dark-500 transition-colors group-hover:text-primary-dark-900">
                              {item?.heading}
                            </span>
                          </Hyperlink>
                        )
                      );
                    })}
                  </ul>
                </NavigationMenu.Content>
              </NavigationMenu.Item>
            );
          } else {
            return (
              <NavigationMenu.Item key={item?.sys.id} asChild>
                <Hyperlink className="colors transition hover:text-primary-dark-500" href={item?.link ?? ""}>
                  {item?.heading}
                </Hyperlink>
              </NavigationMenu.Item>
            );
          }
        })}
      </NavigationMenu.List>
    </NavigationMenu.Root>
  );
}
