"use client"

import Script from "next/script"

declare global {
  interface Window {
    Reo: {
      init(config: { clientID: string }): void;
    }
  }
}

export default function ReoScript() {
  return (
    <Script
      id="script-reo"
      strategy="lazyOnload"
      src="https://static.reo.dev/053d80687ea3965/reo.js"
      onLoad={() => {
        window.Reo.init({ clientID: "053d80687ea3965" });
      }}
    />
  )
}